import React, { useEffect, useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ColorModeContext, tokens } from "../theme";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    MenuItem,
    Modal,
    Tabs, 
    Tab,
    Divider,
  } from '@mui/material';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';

const api = axios.create({baseURL: global_link});


function Escalated() {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  const [newticketopen, setNewTicketOpen] = useState(false);
  const [newticketeditopen, setNewTickeEdittOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [commentopen, setCommentOpen] = useState(false);
  const [commentlistopen, setCommentListOpen] = useState(false);
  const [selectedItemComment, setSelectedItemComment] = useState(null);
  const [comments, setComments] = useState([]);
  const [IndividualValue, setIndividualValue] = useState([]);
  const [UserID, setUserID] = useState([]);
  const [TickeCategoryOpen, setTickeCategoryOpen] = useState(false);
  const [TickeSubCategoryOpen, setTickeSubCategoryOpen] = useState(false);
  const [EmailBlastOpen, setEmailBlastOpen] = useState(false);
  const [TicketCatgoryData, setTicketCatgoryData] = useState([]);

  
  
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    borderRadius: '10px',
    boxShadow: 24,
    p: 3,
  };


  // CATEGORIES
  const TicketCatgoryDataStatic = [
    { category: 'Login Issues', subcategories: ['OTP issues', 'App not Launching'] },
    { category: 'Transaction issues', subcategories: ['Declined transaction', 'Pending Transaction'] },
    { category: 'Reversal issues', subcategories: ['Airtime Reversal', 'Payless to Mpesa', 'Paylesa to Payless', 'Wrong Till Number', 'Payless to Bank'] },
    { category: 'Merchant issues', subcategories: ['Funds Not Reflecting', 'Till/Paybill not accepting payments'] },
    { category: 'Account status', subcategories: ['Suspended', 'Inactive', 'Blocked', ''] },
    { category: 'Loyalty points', subcategories: ['unable to redeem points', 'points not accumulating'] },
    { category: 'Fraud', subcategories: ['Conned', 'Attempted'] },
    { category: 'Feedback', subcategories: ['Suggestions'] },
    { category: 'Tariffs', subcategories: ['	Transaction fees inquiries', 'Transaction charge disputes', 'Maximum/Minimum Limit Queries'] },
    { category: 'Statement', subcategories: ['How to get statement', 'Missing Transactions', 'Unable to retrieve'] },
    { category: 'Fraud Reports', subcategories: ['Conned','Unauthorized Access','Called by Fraudsters', 'Reporting a suspected Fraud','Physical Fraud'] },
    // Add more categories and subcategories as needed
  ];

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const handleInputCategoryChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'category') {
      setSelectedCategory(value);
      setSelectedSubcategory(''); // Reset subcategory when category changes
    } else if (name === 'subcategory') {
      setSelectedSubcategory(value);
    }
  };

  const handleInputCategoryModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
    if (name === 'category') {
      setSelectedCategory(value);
      setSelectedSubcategory(''); // Reset subcategory when category changes
    } else if (name === 'sub_category') {
      setSelectedSubcategory(value);
    }
  };

  const fetchTicketData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/alltickets/',
        { params: {
          search: 'Escalated',
          page: controller.page,
          size: controller.rowsPerPage,
        },
          headers,
          withCredentials: true,
        }
      );
      const data = response.data;
      setFilteredData(data.results);
      setTotalItems(data.count);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // ticketblastingmail
  // const handleNewTicketSubmit = (event) => {
  //   event.preventDefault();
  //   api.post('/addticket/', formData,{ withCredentials: true})
  //     .then((response) => {
  //       fetchTicketData();
  //       setNewTicketOpen(false);
        
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const handleNewTicketSubmit = (event) => {
    event.preventDefault();

    api.post('/addticket/', formData, { withCredentials: true })
      .then((response) => {
        fetchTicketData();
        setNewTicketOpen(false);

        const formData = new FormData(event.target);
        const subject = formData.get('subject');
        const description = formData.get('description');
        const status = formData.get('status');
        const priority = formData.get('priority');
        const category = formData.get('category');
        const subcategory = formData.get('subcategory');
        const channel = formData.get('channel');
        const phone_number = formData.get('phone_number');
        const mail_to = formData.get('mail_to');
        const alternative_phone_number = formData.get('alternative_phone_number');
        const message = `Subject: ${subject}\nStatus: ${status}\nPhone Number: ${phone_number}\nAlternative Phone Number: ${alternative_phone_number}\nChannel: ${channel}\nCategory: ${category}\nPriority: ${priority}\nDescription: ${description}\nSub Category: ${subcategory}`;
        const blastingMailParams = {message: message, subject: subject, mail_to: mail_to }
        // Another API call
        api.post('/ticketblastingmail/', blastingMailParams, { withCredentials: true })
          .then((anotherResponse) => {
            console.log(anotherResponse);
          })
          .catch((anotherError) => {
            console.error(anotherError);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  

  const handleEditTicketSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updateticket/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        fetchTicketData();
        setNewTickeEdittOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleNewCommentSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addcomment/', formData,{ withCredentials: true})
      .then((response) => {
        
        setCommentOpen(false);
        setNewTickeEdittOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  // TABLE 1
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    fetchTicketData();
  }, [searchText, controller]);


  const handleChangePage = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 1
    });
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setNewTickeEdittOpen(true);
  };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };


  const handleAddCommentClick = (tckid) => {
    setCommentOpen(true);
    setSelectedItemComment(tckid);
  };

  const handleCommentListClick = (tckid) => {
    setCommentListOpen(true);
    fetchComments(tckid);
    

  };
  

  const fetchComments = (value) => {
    const accessToken = localStorage.getItem("access_token");
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json",
    };
      api.get(`/getcomments/${value}`,
      {
        headers,
        withCredentials: true,
      }).then(response => {
        setComments(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  };


  const priorityColors = (priority) => {
    switch (priority) {
      case 'Critical':
        return 'red';
      case 'High':
          return 'orange';
      case 'Medium':
        return 'darkorange';
      case 'Internal Resolving':
        return 'darkorange';
      case 'Normal':
        return 'blue';
      case 'Urgent':
        return 'red';
      case 'Escalated':
        return 'purple';
      default:
        return 'inherit';
    }
  };

  const statusColors = (status) => {
    switch (status) {
      case 'Open':
        return 'red';
      case 'Assigned':
        return 'orange';
      case 'In Progress':
        return 'darkorange';
      case 'Pending':
        return 'blue';
      case 'On Hold':
        return 'red';
      case 'Resolved':
        return 'green';
      case 'Closed':
          return 'darkgreen';
      case 'Reopened':
          return 'darkred';
      case 'Escalated':
          return 'lightred';
      case 'Awaiting Customer Feedback':
          return 'purple';
      default:
        return 'inherit';
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};
    const indivvalue = "";
    const params = {search : indivvalue}
    api.post(`/getusers/`,params,{
      headers,
      withCredentials: true,
    })
      .then(response => {
        const indivresultdata = response.data;
        const filteredIndResults = indivresultdata.filter(item =>
          item.user_name.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_id.toLowerCase().includes(indivvalue.toLowerCase()) || 
          item.user_extension.toLowerCase().include(indivvalue.toLowerCase())
        );
        setIndividualValue(filteredIndResults);
      })
      .catch(error => {
        console.error(error);
      });

      api.get('/user/', {
        headers,
        withCredentials: true,
      })
        .then(response => {
    setUserID(response.data.user_id)
        });
    }, []);

      const [searchTicketText, setSearchEmailText] = useState('');
      const [filteredTicketData, setFilteredAssignedData] = useState([]);
      const [totalTicketItems, setTotalAssignedItems] = useState(0);
      const [ticketcontroller, setTicketController] = useState({
        ticketpage: 1,
        ticketrowsPerPage: 10
      });
      
    
      const fetchTicketAssignedData = async () => {
        try {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          const response = await api.get('/assignedticket/',
            { params: {
              search: searchTicketText,
              page: ticketcontroller.ticketpage,
              size: ticketcontroller.ticketrowsPerPage,
            },
              headers,
              withCredentials: true,
            }
          );
          const data = response.data;
          setFilteredAssignedData(data.results);
          setTotalAssignedItems(data.count);
        } catch (error) {
          console.error(error);
        }
      };
    
      useEffect(() => {
        fetchTicketAssignedData();
      }, [searchTicketText, ticketcontroller]);
    
    
      const handleTicketRowClick = (item) => {
        setSelectedItem(item);
      };
    
      const handleTicketChangePage = (event, newPage) => {
        setTicketController({
          ...ticketcontroller,
          ticketpage: newPage
        });
      };
    
      const handleTicketChangeRowsPerPage = (event) => {
        setTicketController({
          ...ticketcontroller,
          ticketrowsPerPage: parseInt(event.target.value, 10),
          ticketpage: 1
        });
      };
    

      
    
  
  
 


        const fetchTicketCategoryData = () => {
          const accessToken = localStorage.getItem("access_token");
          const headers = {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          };
          api.get('/ticketcategory/', {
            headers,
            withCredentials: true,
          })
            .then(response => {
              setTicketCatgoryData(response.data);
            })
            .catch(error => {
              console.error(error);
            });
  
        }

        useEffect(() => {
          fetchTicketCategoryData();
        }, []);

      const handleTicketCategorySubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addticketcategory/', formData,{ withCredentials: true})
          .then((response) => {
            setTickeCategoryOpen(false);
            fetchTicketCategoryData();
          })
          .catch((error) => {
            console.error(error);
          });
      };

      const handleTicketSubCategorySubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        api.post('/addticketsubcategory/', formData,{ withCredentials: true})
          .then((response) => {
            setTickeSubCategoryOpen(false);
            fetchTicketCategoryData();
          })
          .catch((error) => {
            console.error(error);
          });
      };


      const handleDeleteTicketCategorySubmit = (id) => {
        api.delete(`/deleteticketcategory/${id}`,{ withCredentials: true})
          .then((response) => {
            fetchTicketCategoryData();
          })
          .catch((error) => {
            console.error(error);
          });
      };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3}}>
      <TokenExpiration/>
      <Expiration/>
    <Typography variant="h6" paddingBottom={2}>Esacalated</Typography>
    <div>
      <Tabs value={selectedTab} onChange={handleTabChange}   textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
  sx={{ backgroundColor: `${colors.primary[700]} !important`}}>
        <Tab label="All Tickets" onClick={fetchTicketData} />
        <Tab label="Assigned" onClick={fetchTicketAssignedData} />
        {sessionStorage.getItem('user_level') === "supervisor" && (<Tab label="Settings" />)}
        {sessionStorage.getItem('user_level') === "admin" && (<Tab label="Settings" />)}
      </Tabs>
      <Divider/>

      {selectedTab === 0 && (
        <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
          <Typography variant="body1">
            <Box display='flex' justifyContent='right'>
            <Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => fetchTicketData()}>
      <AutorenewIcon sx={{ mr: "5px" }} /> Refresh</Button>

<Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setNewTicketOpen(true)}>
      <AddIcon sx={{ mr: "5px" }} /> New Ticket</Button>
              </Box>
          
              <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
            <TableCell>Ticket No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Sub Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell sx={{ color: priorityColors(row.priority), fontWeight: 'bold',}}>{row.priority}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.sub_category}</TableCell>
                  <TableCell sx={{ color: statusColors(row.status), fontWeight: 'bold',}}>{row.status}</TableCell>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell>{row.created_at.slice(0, -16).replace('T', ' ')}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleRowClick(row)}><VisibilityIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />

        </Typography>



        <Modal
        open={newticketopen}
        onClose={() => setNewTicketOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">New Ticket</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewTicketOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleNewTicketSubmit}>
          <Grid container spacing={1}>

          <Grid item xs={12}>
<TextField
              label="Description"
              name="subject"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
            />
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Priority"
        name="priority"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="">Select Ticket Priority</MenuItem>
        <MenuItem value="Critical">Critical ( P 1 )</MenuItem>
        <MenuItem value="High">High ( P 2 )</MenuItem>
        <MenuItem value="Medium">Medium ( P 3 )</MenuItem>
        <MenuItem value="Internal Solving">Internal Solving ( P 4 )</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
              label="Phone Number"
              name="phone_number"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="(Optional) Alternative Phone Number"
              name="alternative_phone_number"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              sx={{marginTop:"5px"}}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="Email (Optional)"
              name="email"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              sx={{marginTop:"5px"}}
            /> 
</Grid>





<Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Ticket Category"
          name="category"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedCategory}
        >
          <MenuItem disabled>Select Ticket Category</MenuItem>
          {TicketCatgoryDataStatic.map((row) => (
            <MenuItem key={row.category} value={row.category}>
              {row.category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Ticket Sub Category"
          name="subcategory"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedSubcategory}
        >
          <MenuItem disabled>Select Ticket Sub Category</MenuItem>
          {selectedCategory &&
            TicketCatgoryDataStatic.find((row) => row.category === selectedCategory).subcategories.map((subcat) => (
              <MenuItem key={subcat} value={subcat}>
                {subcat}
              </MenuItem>
            ))}
        </TextField>
      </Grid>




{/* <Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Assigned To"
        name="assigned_to"
        onChange={handleInputChange}
        sx={{marginTop:"5px"}}
      >
        
<MenuItem disabled>Select User</MenuItem>
{IndividualValue.map(item => (
  <MenuItem value={item.user_id} sx={{display: item.user_id === UserID ? 'none' : '',}}>{item.user_name} ({item.user_extension})</MenuItem>

))}
      </TextField>
</Grid> */}

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Assigned To"
        name="mail_to"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="" disabled>Select</MenuItem>
        <MenuItem value="renyyrozz@gmail.com">Support</MenuItem>
        <MenuItem value="finance@paylessafrica.zohodesk.com">Finance</MenuItem>
        <MenuItem value="techops@paylessafrica.zohodesk.com">Techops</MenuItem>
        <MenuItem value="product@paylessafrica.zohodesk.com">Product</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Channel"
        name="channel"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="">Select Ticket Channel</MenuItem>
        <MenuItem value="Phone Call">Phone Call</MenuItem>
        <MenuItem value="WhatsApp">WhatsApp</MenuItem>
        <MenuItem value="Email">Email</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Twitter">Twitter</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Chatbot">Chatbot</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Ticket Status"
        name="status"
        onChange={handleInputChange}
        required="true"
        sx={{marginTop:"5px"}}
      >
        <MenuItem value="">Select Ticket Status</MenuItem>
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Assigned">Assigned</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Resolved">Resolved</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
        <MenuItem value="Escalated">Escalated</MenuItem>
        <MenuItem value="Awaiting Customer Feedback">Awaiting Customer Feedback</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
          <TextField
            multiline
            rows={3}
            label="Ticket Description"
            placeholder="Ticket Description"
            variant="outlined"
            fullWidth
            name="description"
            onChange={handleInputChange}
            sx={{marginTop:"5px"}}
          />
        </Grid>

<Grid item xs={12}>
            <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Ticket</Button>
            </Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>



      


        </Box>




      )}

      {selectedTab === 1 && (
        <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
        <Typography variant="body1">
        <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
      <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
            <TableCell>Ticket No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Subject</TableCell>
              <TableCell>Priority</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Sub Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Channel</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredTicketData
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.subject}</TableCell>
                  <TableCell sx={{ color: priorityColors(row.priority), fontWeight: 'bold',}}>{row.priority}</TableCell>
                  <TableCell>{row.phone_number}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.category}</TableCell>
                  <TableCell>{row.sub_category}</TableCell>
                  <TableCell sx={{ color: statusColors(row.status), fontWeight: 'bold',}}>{row.status}</TableCell>
                  <TableCell>{row.channel}</TableCell>
                  <TableCell>{row.created_at.slice(0, -16).replace('T', ' ')}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleRowClick(row)}><VisibilityIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
            <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalTicketItems}
        rowsPerPage={ticketcontroller.ticketrowsPerPage}
        page={ticketcontroller.ticketpage}
        onPageChange={handleTicketChangePage}
        onRowsPerPageChange={handleTicketChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />

      </Typography>
      </Box>
      )}


{selectedTab === 2 && (
        <Box sx={{marginTop:'20px',paddingLeft:'10px', flexGrow: 1,}}>
        <Typography variant="body1">
        <Box display='flex' justifyContent='right'>

        <Button variant='contained'
    sx={{
      backgroundColor: '#33ab61',
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setEmailBlastOpen(true)}>
      <AddIcon sx={{ mr: "10px" }} /> New Blast Email</Button>

        <Button variant='contained'
    sx={{
      backgroundColor: '#90ba61',
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setTickeSubCategoryOpen(true)}>
      <AddIcon sx={{ mr: "10px" }} /> New Ticket Sub Category</Button>

<Button variant='contained'
    sx={{
      backgroundColor: colors.blueAccent[600],
      color: colors.grey[100],
      fontSize: "13px",
      fontWeight: "bold",
      padding:'8px',
      height: '35px',
      mr: "15px;"
    }}
    onClick={() => setTickeCategoryOpen(true)}>
      <AddIcon sx={{ mr: "10px" }} /> New Ticket Category</Button>
              </Box>
      </Typography>

      <Table sx={{marginTop:"20px" }}>
        <TableHead sx={{backgroundColor:`${colors.primary[600]}`}}>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{backgroundColor:`${colors.primary[700]}`}}>
        {TicketCatgoryData.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: 'red'}} onClick={() => handleDeleteTicketCategorySubmit(row.id)}><DeleteForeverIcon/></Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Box>
      )}




<Modal
        open={newticketeditopen}
        onClose={() => setNewTickeEdittOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Ticket No #{selectedItem && (selectedItem.id)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setNewTickeEdittOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleEditTicketSubmit}>
          {selectedItem && (
          <Grid container spacing={1}>

          <Grid item xs={12}>
<TextField
              label="Description"
              name="subject"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              value={selectedItem.subject || ""}
            />
            <TextField    
              type="hidden"
              name="id"
              value={selectedItem.id}
              style={{ display: 'none' }}
              required="true"
            />
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Priority"
        name="priority"
        onChange={handleInputModChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.priority || ""}
      >
        <MenuItem value="">Select Ticket Priority</MenuItem>
        <MenuItem value="Critical">Critical ( P 1 )</MenuItem>
        <MenuItem value="High">High ( P 2 )</MenuItem>
        <MenuItem value="Medium">Medium ( P 3 )</MenuItem>
        <MenuItem value="Internal Solving">Internal Solving ( P 4 )</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
              label="Phone Number"
              name="phone_number"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"5px"}}
              value={selectedItem.phone_number || ""}
            />
</Grid>

<Grid item xs={6}>
<TextField
              label="(Optional) Alternative Phone Number"
              name="alternative_phone_number"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              sx={{marginTop:"5px"}}
              value={selectedItem.alternative_phone_number || ""}
            />
</Grid>

<Grid item xs={6}> 
<TextField
              label="Email (Optional)"
              name="email"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              sx={{marginTop:"5px"}}
              value={selectedItem.email || ""}
            />
</Grid>



<Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Ticket Category"
          name="category"
          onChange={handleInputCategoryModChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedCategory || ""}
        >
          <MenuItem disabled>Select Ticket Category</MenuItem>
          {TicketCatgoryDataStatic.map((row) => (
            <MenuItem key={row.category} value={row.category}>
              {row.category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Ticket Sub Category"
          name="sub_category"
          onChange={handleInputCategoryModChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedSubcategory}
        >
          <MenuItem disabled>Select Ticket Sub Category</MenuItem>
          {selectedCategory &&
            TicketCatgoryDataStatic.find((row) => row.category === selectedCategory).subcategories.map((subcat) => (
              <MenuItem key={subcat} value={subcat}>
                {subcat}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

<Grid item xs={6}>
      <TextField
        fullWidth
        select
        label="Assigned To"
        name="assigned_to"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.assigned_to || ""}
      >
        
{IndividualValue.map(item => (
  <MenuItem value={item.user_id} sx={{display: item.user_id === UserID ? 'none' : '',}}>{item.user_name} ({item.user_extension})</MenuItem>

))}
      </TextField>
</Grid>

<Grid item xs={6}>
<TextField
        fullWidth
        select
        label="Ticket Channel"
        name="channel"
        onChange={handleInputModChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.channel || ""}
      >
        <MenuItem value="">Select Ticket Channel</MenuItem>
        <MenuItem value="Phone Call">Phone Call</MenuItem>
        <MenuItem value="Email">Email</MenuItem>
        <MenuItem value="WhatsApp">WhatsApp</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Twitter">Twitter</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Chatbot">Chatbot</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Ticket Status"
        name="status"
        onChange={handleInputModChange}
        required="true"
        sx={{marginTop:"5px"}}
        value={selectedItem.status || ""}
      >
        <MenuItem value="">Select Ticket Status</MenuItem>
        <MenuItem value="Open">Open</MenuItem>
        <MenuItem value="Assigned">Assigned</MenuItem>
        <MenuItem value="In Progress">In Progress</MenuItem>
        <MenuItem value="Resolved">Resolved</MenuItem>
        <MenuItem value="Closed">Closed</MenuItem>
        <MenuItem value="Escalated">Escalated</MenuItem>
        <MenuItem value="Awaiting Customer Feedback">Awaiting Customer Feedback</MenuItem>
      </TextField>
</Grid>

<Grid item xs={12}>
          <TextField
            multiline
            rows={3}
            label="Ticket Description"
            placeholder="Ticket Description"
            variant="outlined"
            fullWidth
            name="description"
            onChange={handleInputModChange}
            sx={{marginTop:"5px"}}
            value={selectedItem.description || ""}
          />
        </Grid>

        <Grid item xs={12} sx={{display:"space-between"}}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[600]}}>Update Ticket</Button>
          <Button type="button" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[700]}} onClick={() => handleAddCommentClick(selectedItem.ticket_id)}>Add Comment</Button>
          <Button type="button" variant="contained"  onClick={() => handleCommentListClick(selectedItem.ticket_id)} sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: colors.blueAccent[800]}}>Comments</Button>
          <Button type="button" variant="contained" onClick={() => setNewTickeEdittOpen(false)} sx={{padding:"10px",marginTop:"10px",color:'white',backgroundColor: 'red'}}>Close</Button>
          </Box>

            </Grid>

            </Grid>
          )}
          </form>
        </div>
        </Box>
      </Modal>





      <Modal
        open={TickeCategoryOpen}
        onClose={() => setTickeCategoryOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Ticket Category</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setTickeCategoryOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleTicketCategorySubmit}>
<Grid container spacing={1}>
<Grid item xs={12}>
          <TextField
          fullWidth    
              type="text"
              label="Ticket Category"
              placeholder="Ticket Category"
              name="category"
              onChange={handleInputChange}
              required="true"
            />
   </Grid>


   </Grid> 
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Add Category</Button>
          </form>

        </div>
        </Box>
        
      </Modal>




      <Modal
        open={TickeSubCategoryOpen}
        onClose={() => setTickeSubCategoryOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Ticket Sub Category</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setTickeSubCategoryOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleTicketSubCategorySubmit}>
<Grid container spacing={1}>


<Grid item xs={12}>
<TextField
          fullWidth
          select
          label="Ticket Category"
          name="category"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedCategory}
        >
          <MenuItem disabled>Select Ticket Category</MenuItem>
          {TicketCatgoryDataStatic.map((row) => (
            <MenuItem key={row.category} value={row.category}>
              {row.category}
            </MenuItem>
          ))}
        </TextField>
</Grid>

<Grid item xs={12}>
          <TextField
          fullWidth    
              type="text"
              label="Ticket Sub Category"
              placeholder="Ticket Sub Category"
              name="sub_category"
              onChange={handleInputChange}
              required="true"
            />
   </Grid>


   </Grid> 
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Add Sub Category</Button>
          </form>

        </div>
        </Box>
        
      </Modal>


      <Modal
        open={commentopen}
        onClose={() => setCommentOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Comment</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setCommentOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={handleNewCommentSubmit}>
            <TextField
            multiline
            rows={3}
            label="Ticket Comment"
            placeholder="Ticket Comment"
            variant="outlined"
            fullWidth
            name="comment"
            onChange={handleInputChange}
            sx={{marginTop:"5px"}}
          />
          
        
          <TextField    
              type="hidden"
              name="ticket_id"
              value={selectedItemComment}
              style={{ display: 'none' }}
              required="true"
            />
    
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Comment</Button>
          </form>
            </div>
            </Box>
            </Modal>


            <Modal
        open={commentlistopen}
        onClose={() =>setCommentListOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Comment List For Ticket No #{selectedItem && (selectedItem.id)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setCommentListOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>


<Box sx={{height:'300px',overflowY:'auto'}}>
{comments.map((cmmnts,i) => (
  <Box variant="h5" p={2} m={1} sx={{backgroundColor: colors.primary[600], borderRadius:'5px'}}><Box>{cmmnts.comment}</Box> <br/> <Box display='flex' justifyContent='right'>{cmmnts.created_at.slice(0, -9).replace('T', ' ')}</Box></Box>
))}
</Box>

    

            </div>
            </Box>
            </Modal>




    </div>

  </Box>
  );
}

export default Escalated;


