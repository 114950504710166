import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Modal,
    Box,
    Typography,
    Grid,
    Tab,
    Tabs,
  } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../theme";
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const KnowledgeBase = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [scriptopen, setScriptOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [selectedItem, setSelectedItem] = useState(null);
    const [scripts, setScripts] = useState(null);
    const [scripteditopen, setScriptEditOpen] = useState(false);
    const [scripteditopenreadonly, setScriptEditOpenReadonly] = useState(false);

    const [searchScriptValue, setSearchScriptValue] = useState('');
    const [searchScriptResults, setSearchScriptResults] = useState([]);

    
  
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        borderRadius: '10px',
        boxShadow: 24,
        p: 3,
      }

const api = axios.create({baseURL: global_link});

const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleScriptSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    api.post('/addscript/', formData,{ withCredentials: true})
      .then((response) => {
        setScriptOpen(false);
        setScriptResultsUp();
      })
      .catch((error) => {
        console.error(error);
      });
   }


   const handleScriptEditSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updatescript/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        setScriptResultsUp();
        setScriptEditOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleSearchScriptChange = (event) => {
    const searchvalue = event.target.value;
    setSearchScriptValue(searchvalue);
    const accessToken = localStorage.getItem('access_token');
    const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',};
    api.get('/allscript/', {
      headers,
      withCredentials: true,
    })
      .then(response => {
        const scriptresultdata = response.data;
        const filteredScriptResults = scriptresultdata.filter(item =>
          item.header.toLowerCase().includes(searchvalue.toLowerCase()) || 
          item.script.toLowerCase().includes(searchvalue.toLowerCase()) || 
          item.department.toLowerCase().includes(searchvalue.toLowerCase())
        );
      
        setSearchScriptResults(filteredScriptResults);
      })
      .catch(error => {
        console.error(error);
      });
  };


  const setScriptResultsUp = (event) => {
        const searchvalue = "";
        setSearchScriptValue(searchvalue);
        const accessToken = localStorage.getItem('access_token');
        const headers = {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',};
        api.get('/allscript/', {
          headers,
          withCredentials: true,
        })
          .then(response => {
            const scriptresultdata = response.data;
            const filteredScriptResults = scriptresultdata.filter(item =>
              item.header.toLowerCase().includes(searchvalue.toLowerCase()) || 
              item.script.toLowerCase().includes(searchvalue.toLowerCase()) || 
              item.department.toLowerCase().includes(searchvalue.toLowerCase())
            );
          
            setSearchScriptResults(filteredScriptResults);
          })
          .catch(error => {
            console.error(error);
          });
      };

      useEffect(() => {
        setScriptResultsUp();
        },[]);


  const handleRowClick = (item) => {
    setSelectedItem(item);
    setScriptEditOpen(true);
  };

  // const handleRowClickReadOnly = (item) => {
  //   setSelectedItem(item);
  //   setScriptEditOpenReadonly(true);
  // };

  const handleInputModChange = (event) => {
    const { name, value } = event.target;
    setSelectedItem((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };



  const handleScriptDelete = (id) => {
    api.delete(`/deletescript/${id}`, { withCredentials: true })
      .then((response) => {
        setScriptResultsUp();
        setScriptEditOpen(false);
      })
      .catch((error) => {
        setScriptEditOpen(true);
      });
  };
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
        <TokenExpiration/>
        <Expiration/>
    <Typography variant="h6" paddingBottom={2}> Trouble Shooting</Typography>
    <Box display="flex" justifyContent="space-between">
<Box>
<Grid>
<Grid item xs={12}>
<Tabs
  value={selectedTab}
  onChange={handleTabChange}
  textColor="secondary"
  indicatorColor="secondary"
  variant="fullWidth"
      >
        <Tab label="Tutorials"/>
        <Tab  label="Common Errors and Solutions"/>
        <Tab  label="Toubleshooting Wizards"/>

      </Tabs>
        </Grid>
        </Grid>
    </Box>

    <Box>
    <Button
        variant='contained'
        sx={{
          backgroundColor: colors.blueAccent[600],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "10px 20px",
          mr: "15px;",
          display:'none',
        //   display: sessionStorage.getItem("user_level")==='supervisor' || sessionStorage.getItem("user_level")==='admin' ? '' : 'none',
        }}
        onClick={() => setScriptOpen(true)}
      >
        <AddIcon sx={{ mr: "10px" }} />
        Add Script
      </Button>
      </Box>
    </Box>



    {selectedTab === 0 && <span>
        <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How to sign up on the App</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Click on 'Don't have a registered Account? Signup' Link</li>
<li>Sign up by filling the below</li>
<li>Mobile Number</li>
<li>Email ID (Optional)</li>
<li>Then click GET OTP Button</li>
<li>You will receive a 4 - digit OTP on SMS, Enter the OTP and click on the verify button</li>
<li>You will be redirected to Set PIN screen</li>
<li>Enter a preferred 4-digit pin, re-enter the PIN then click Sign up Button</li>
<li>You will get a message 'PIN Set successfully' and Enable biometrics check box with Proceed Button</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>How to complete KYC level 2</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Login with your registered number and click on any icon</li>
      <li>Select ID Type from the dropdown</li>
      <li>Enter ID/PASSPORT depending on selected type</li>
      <li>Click on Proceed Button then fill in the below</li>
      <li>First Name</li>
      <li>Last Name</li>
      <li>DOB</li>
      <li>Gender</li>
      <li>If the details are correct, Click Proceed to continue</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  
    </span>}

    {selectedTab === 1 && <span>

        <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>PIN Match Error</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the customer to input correct pin. If on sign up/initial set up, customer should use the same PIN on both slots</li>
      <li>On sign up</li>
      <li>If customer insists PIN is correct but error persists, advise customer to use Forgot PIN to create a new PIN</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Your KYC request has been rejected</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the customer to enter the correct details as per the national ID</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Your KYC request is under review due to some reason </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise customer to be patient, KYC will be approved in a few minutes</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Mobile Number Doesn’t Exist</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise customer to use the exact number he/she registered with. Alternatively, customer can register the new number</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Entered OTP is incorrect. Please enter correct OTP </b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the Customer to confirm the OTP and enter correct one. Alternatively, generate another OTP</li>
      </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>Unable to Upload ID doc for KYC upgrade</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Advise the customer to take a photo of the original ID, not photocopy of ID or photo of random documents e.g. DL. Note that there is no option for attaching ID from downloads</li>
      </Typography>
    </AccordionDetails>
  </Accordion>
    </span>}


    {selectedTab === 2 && <span>
        <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>1. OTP ISSUES</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Troubleshooting
      <li>Ask customer if they are able to receive other SMS besides payless, if they cannot receive any SMS, it is a handset issue</li>
      <li>Ask customer to check if the SMS is on SPASM/blocked messages</li>
      <li>Ensure customer has not blocked promotional Messages</li>
      <li>If the customer is using a dual sim, confirm the number customer is using is ON/enabled</li>
      <li>If all the above fail, escalate and advise customer on SLA </li>
      <li>Advise the customer to take a photo of the original ID, not photocopy of ID or photo of random documents e.g. DL. Note that there is no option for attaching ID from downloads</li>

      <br/>
      Escalation
      <li>Get type of handset customer is using</li>
      <li>Get the number customer is registering on the App</li>
      <li>Ask the customer if there is an error message and capture it</li>
      <li>Capture all the above on description and advise customer on SLA</li>

      </Typography>
    </AccordionDetails>
  </Accordion>


  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>2.	Transaction Issues(Declined/Failed/pending)</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>


      Troubleshooting
      <li>Confirm from BO if customer is registered up to level 3</li>
      <li>Confirm that the customer's account is not suspended/inactive/dormant/Blocked</li>
      <li>Confirm that the customer has sufficient funds (inclusive of transaction fees)</li>
      <li>Confirm the type of transaction (On-net, Bank, Airtime etc.)</li>
      <li>Confirm amount involved is within allowed maximum limit, if not advise customer on acceptable limits</li>
      <li>Confirm if the funds have already been deducted from the customers payless Wallet and if not, customer can retry</li>
      <li>If all the above is OK, escalate</li>
      <br/>
     Escalation
        <li>Confirm if there is an error message and capture in on the escalation</li>
        <li>Indicate type of transaction and amount</li>
        <li>capture the number registered on the App</li>
        <li>Advise customer on SLA</li>

     </Typography>
    </AccordionDetails>
  </Accordion>



  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>3. SUSPENDED ACCOUNT</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      <li>Check if there is a comment/reason for suspension and advise the customer accordingly. If no reason is captured, Escalate. Capture the suspended number and an alternative contact</li>
     </Typography>
    </AccordionDetails>
  </Accordion>

  <Accordion sx={{ margin:'5px'}}>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel-content"
      id="panel-header"
      sx={{ borderStyle:'none',borderColor: colors.primary[600], backgroundColor: colors.primary[600], marginTop:'30px',boder:'none!important'}}
    >
      <Typography variant="h6"><b>  4.	ADD MONEY ISSUES (unable to add, Money added but balance not updated)</b></Typography>
    </AccordionSummary>
    <AccordionDetails sx={{paddingTop:'20px', paddingBottom:'20px'}}>
      <Typography variant='h6'>
      Troubleshooting
      <li>Confirm if customer is adding from M-PESA, Bank or Primary Wallet</li>
      <li>Confirm the error the customer is getting</li>
      <li>Confirm from the customer if source has sufficient balance inclusive of charges</li>
      <li>Confirm if the funds have already been deducted from the source, if not, customer can retry</li>
      <li>Confirm that the payless wallet balance is within limit, if not advise customer on acceptable limits</li>
      <li>If all above fails, escalate</li>
</Typography>
    </AccordionDetails>
  </Accordion>





    </span>}

    {selectedTab === 3 && <span>

    </span>}
    {selectedTab === 4 && <span>

    </span>}




    <Modal
        open={scriptopen}
        onClose={() => setScriptOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Script / Reminder</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
<form onSubmit={ handleScriptSubmit}>


      <TextField
            label="Department"
            placeholder="Department"
            variant="outlined"
            fullWidth
            name="department"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
          />

<TextField
            label="Header"
            placeholder="Header"
            variant="outlined"
            fullWidth
            name="header"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
          />

            <TextField
            multiline
            rows={6}
            label="Script"
            placeholder="Script"
            variant="outlined"
            fullWidth
            name="script"
            onChange={handleInputChange}
            sx={{marginTop:"15px"}}
            required="true"
          />
          
        
    
          <Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Submit Script</Button>
          </form>
            </div>
            </Box>
            </Modal>





            
    <Modal
        open={scripteditopen}
        onClose={() => setScriptEditOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Script</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptEditOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
           
<form onSubmit={handleScriptEditSubmit}>
{selectedItem && ( 
    <Grid container spacing={1}>

<Grid item xs={12}>
<TextField
        fullWidth
        label="Department"
        name="department"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.department || ""}
      />
</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        label="Header"
        name="header"
        onChange={handleInputModChange}
        sx={{marginTop:"5px"}}
        value={selectedItem.header || ""}
      />
</Grid>

<Grid item xs={12}>
            <TextField
            multiline
            rows={8}
            label="Script"
            placeholder="Script"
            variant="outlined"
            fullWidth
            name="script"
            onChange={handleInputModChange}
            value={selectedItem.script || ""}
            sx={{marginTop:"15px"}}
            required="true"
          />

<TextField
            variant="outlined"
            fullWidth
            name="id"
            onChange={handleInputModChange}
            value={selectedItem.id || ""}
            sx={{display:"none"}}
            required="true"
            type="hidden"
          />
          </Grid>

          <Grid container item xs={12} sx={{display:'flex', justifyContent:'space-between'}}>

<Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: colors.blueAccent[600]}}>Edit Script</Button>
<Button type="button" variant="contained" sx={{padding:"10px",marginTop:"12px",color:'white',backgroundColor: 'red'}} onClick={() => handleScriptDelete(selectedItem.id)}>Delete</Button>

</Grid>
          </Grid>
          )}


    
          

</form>
            </div>
            </Box>
            </Modal>





            <Modal
        open={scripteditopenreadonly}
        onClose={() => setScriptEditOpenReadonly(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={style}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">{selectedItem && (selectedItem.department)} Script</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setScriptEditOpenReadonly(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
            
{selectedItem && ( 
<Grid container spacing={1}>
<Grid item xs={12}><br/>
<h2>{selectedItem.script}</h2>
</Grid></Grid>
          )}

            </div>
            </Box>
            </Modal>





    </Box>
  );
};

export default KnowledgeBase;

