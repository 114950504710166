import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PhoneMissedIcon from '@mui/icons-material/PhoneMissed';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import CallIcon from '@mui/icons-material/Call';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Button,
    Modal,
    MenuItem,
  } from '@mui/material';
  import AddIcon from '@mui/icons-material/Add';
  import CancelIcon from '@mui/icons-material/Cancel';

import axios from 'axios';
import { global_link } from '../Components/Globallink';
import { ColorModeContext, tokens } from "../theme";
import TokenExpiration from '../Auth/TokenExpiration';
import Expiration from '../Components/Expiration';


const api = axios.create({baseURL: global_link});

function Disposions() {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedTab, setSelectedTab] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [controller, setController] = useState({
    page: 1,
    rowsPerPage: 10
    });
    const [dispositionopen, setDispositionOpen] = useState(false);
    const [ extrachange, setExtraChange] = useState('');
    const [ dispositions, setDispositionChange] = useState('');
    const [formData, setFormData] = useState({});

    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const modalStyle =  {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 450,
      borderRadius: '10px',
      boxShadow: 24,
      p: 3,
    };


      // CATEGORIES
  const TicketCatgoryDataStatic = [
    { category: 'Login Issues', subcategories: ['OTP issues', 'App not Launching','Forgot Pin','Other'] },
    { category: 'Transaction issues', subcategories: ['Wallet Verification Pending','Pending Transaction','STK push issues','Declined/Failed transactions ','Transaction charges disputes','Other'] },
    { category: 'Reversal issues', subcategories: ['Airtime Reversal', 'Payless to Mpesa', 'Paylesa to Payless', 'Wrong Till Number', 'Payless to Bank','Other'] },
    { category: 'Merchant issues', subcategories: ['Funds Not Reflecting', 'Till/Paybill not accepting payments','Unlisted Billers','Other'] },
    { category: 'Account status', subcategories: ['Suspended', 'Inactive', 'Blocked', 'Restricted','Active','Other'] },
    { category: 'Loyalty points', subcategories: ['unable to redeem points', 'points not accumulating','Enrollment','Redeemable/ offers','Other'] },
    { category: 'Fraud', subcategories: ['Conned', 'Attempted','Other'] },
    { category: 'Feedback', subcategories: ['Suggestions','Complaints','Compliments','Other'] },
    { category: 'Tariffs', subcategories: ['Transaction fees inquiries', 'Transaction charge disputes', 'Maximum/Minimum Limit Queries','Other'] },
    { category: 'Statement', subcategories: ['How to get statement', 'Missing Transactions', 'Unable to retrieve','Certified statements','Other'] },
    { category: 'Fraud Reports', subcategories: ['Conned','Unauthorized Access','Called by Fraudsters', 'Reporting a suspected Fraud','Physical Fraud','Other'] },
    { category: 'App Downloading', subcategories: ['Android Version','IOS Version','App Identity Issues', 'App missing on Play Store','Other'] },
    { category: 'Customer Onboarding', subcategories: ['Sim Binding','KYC Verification Pending','OTP Issues','KYC Rejected','Other'] },
    { category: 'Downtime issues', subcategories: ['App Downtime/Maintenance','M-pesa downtime','Jambo Pay and other partners','Other'] },
    { category: 'Customer support', subcategories: ['Social media handles','WhatsApp number','Email address','Physical location','Other'] },
    { category: 'Promotions and offers', subcategories: ['Current promotions','Upcoming offers','Merchandise request','Other'] },
    { category: 'Non-service related', subcategories: ['Time queries','Other provider issues','NRG queries','Non-service related issues','Non-Compatible Devices','Other'] },
    { category: 'Product Enquiry', subcategories: ['Product Enquiry','Other'] }
    // Add more categories and subcategories as needed
  ];

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');

  const handleInputCategoryChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === 'category') {
      setSelectedCategory(value);
      setSelectedSubcategory(''); // Reset subcategory when category changes
    } else if (name === 'subcategory') {
      setSelectedSubcategory(value);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRowClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handleEdit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const id = formData.get('id');
    api.put(`/updatedisposition/${id}`, formData,{ withCredentials: true})
      .then((response) => {
        fetchData();
        setIsModalOpen(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const fetchData = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      };
      const response = await api.get('/calldispositions/',
        { params: {
          search: searchText,
          page: controller.page,
          size: controller.rowsPerPage,
        },
          headers,
          withCredentials: true,
        }
      );
      const data = response.data;
      setFilteredData(data.results);
      setTotalItems(data.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
  fetchData();
}, [searchText, controller]);

const handleChangePage = (event, newPage) => {
  setController({
    ...controller,
    page: newPage
  });
};

const handleChangeRowsPerPage = (event) => {
  setController({
    ...controller,
    rowsPerPage: parseInt(event.target.value, 10),
    page: 1
  });
};

const copyPhoneNumber = (call_from, call_to) => {
  if(call_from==(sessionStorage.getItem('extension'))){
    const event = new CustomEvent('copyPhoneNumber', { detail: call_to });
    window.dispatchEvent(event);
  }else{
    const event = new CustomEvent('copyPhoneNumber', { detail: call_from });
    window.dispatchEvent(event);
  }
}

const handleExtraChange = (event) => {
  setExtraChange(event.target.value);
}
const handleDispositionChange = (event) => {
  setDispositionChange(event.target.value);
}

const handleDispositionSubmit  = (event) => {
  event.preventDefault();
  const formData = new FormData(event.target);
  api.post('/adddisposition/', formData,{ withCredentials: true})
    .then((response) => {
      setDispositionOpen(false);
      fetchData();
      setExtraChange('');
      setDispositionChange('');
      setSelectedCategory('');
      setSelectedSubcategory('');
    }).catch((error) => {console.error(error);});
};

const handleInputModChange = (event) => {
  const { name, value } = event.target;
  setSelectedItem((prevFormState) => ({
    ...prevFormState,
    [name]: value,
  }));
};

  return (
    <Box sx={{marginTop:'60px',paddingLeft:'10px', flexGrow: 1, p: 3 }}>
      <TokenExpiration/>
      <Expiration/>
      <Grid item xs={12} display="flex" justifyContent="space-between">
      <Typography variant="h6" paddingBottom={2} sx={{marginBottom:'20px'}}>Dispositions</Typography>
        <Box><Button variant="contained" sx={{padding:"10px",backgroundColor: colors.blueAccent[600],color:'white',textAlign:'center'}}  onClick={() => setDispositionOpen(true)}> <AddIcon/> Add Disposition </Button></Box>
        </Grid>

    

    <Box
        m="40px 0 0 0"
        height="75vh"
        mt="-15px"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            // color: colors.greenAccent[300],
            color: colors.primary[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.primary[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >

<div>
      <TextField
        label="Search"
        variant="outlined"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        style={{ marginBottom: '16px' }}
      />
       <TableContainer>
        <Table>
          <TableHead sx={{backgroundColor: colors.primary[600],borderRadius: '5px 5px 0 0 !important'}}>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Disposition</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Sub Category</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{backgroundColor: colors.primary[700]}}>
            {filteredData
              .map((row, index) => (
                <TableRow key={row.id}   sx={{cursor:'pointer'}} onClick={ () => copyPhoneNumber (row.disposition_owner, row.disposition_contact)}>
                  <TableCell>{row.user_name}</TableCell>
                  <TableCell>{row.disposition_contact}</TableCell>
                  <TableCell><b>{row.disposition}</b></TableCell>
                  <TableCell>{row.disposition_category}</TableCell>
                  <TableCell>{row.disposition_sub_category}</TableCell>
                  <TableCell>{row.disposition_info}</TableCell>
                  <TableCell>{row.disposition_time.slice(0, -13).replace('T', ' ')}</TableCell>
                  <TableCell>{row.call_type}</TableCell>
                  <TableCell><Button key={row.id} variant='contained' sx={{backgroundColor: colors.blueAccent[600]}} onClick={() => handleRowClick(row)}><DriveFileRenameOutlineIcon/></Button></TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10,25,50,100]}
        component="div"
        count={totalItems}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{backgroundColor: colors.primary[600],borderRadius: '0 0 5px 5px'}}
      />
    </div>

      </Box>

      <Modal
        open={dispositionopen}
        onClose={() => setDispositionOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Add Disposition</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setDispositionOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleDispositionSubmit}>
          <Grid container spacing={1}>

          <Grid item xs={6}>
            
<TextField
              label="Disposition Contact"
              name="disposition_contact"
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"10px"}}
            />
          </Grid>
          <Grid item xs={6}>
<TextField
          label="Disposition Owner"
          placeholder="Disposition Owner"
          variant="outlined"
          fullWidth
          name="disposition_owner"
          required="true"
          sx={{marginTop:"10px",display:'none'}}
          value={sessionStorage.getItem('extension')}
        />


      <TextField
        fullWidth
        select
        label="Call Type"
        name="call_type"
        sx={{marginTop:"10px"}}
        onChange={handleInputChange}
        required="true"
      >
        <MenuItem value="">Select Type Of Call</MenuItem>
        <MenuItem value="incoming">Incoming</MenuItem>
        <MenuItem value="outgoing">Outgoing</MenuItem>
      </TextField>

</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Disposition"
        name="disposition"
        sx={{marginTop:"10px"}}
        value={dispositions}
        onChange={handleDispositionChange}
        required="true"
      >
        <MenuItem value="">Select Disposition</MenuItem>
        <MenuItem value="Call Back">Call Back</MenuItem>
        <MenuItem value="Sorted">Sorted</MenuItem>
        <MenuItem value="Not Interested">Not Interested</MenuItem>
        <MenuItem value="Escalating">Escalating</MenuItem>
        <MenuItem value="Would Like To Know More">Would Like To Know More</MenuItem>
      </TextField>
</Grid>

<Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Category"
          name="category"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedCategory}
        >
          <MenuItem disabled>Select Category</MenuItem>
          {TicketCatgoryDataStatic.map((row) => (
            <MenuItem key={row.category} value={row.category}>
              {row.category}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={6}>
        <TextField
          fullWidth
          select
          label="Sub Category"
          name="subcategory"
          onChange={handleInputCategoryChange}
          required
          sx={{ marginTop: '5px' }}
          value={selectedSubcategory}
        >
          <MenuItem disabled>Select Sub Category</MenuItem>
          {selectedCategory &&
            TicketCatgoryDataStatic.find((row) => row.category === selectedCategory).subcategories.map((subcat) => (
              <MenuItem key={subcat} value={subcat}>
                {subcat}
              </MenuItem>
            ))}
        </TextField>
      </Grid>

<Grid item xs={12}>
        <TextField
          multiline
          rows={2}
          label="Extra Info"
          placeholder="Extra Info"
          value={extrachange}
          onChange={handleExtraChange}
          variant="outlined"
          fullWidth
          name="disposition_info"
          required="true"
        />
        </Grid>



<Grid item xs={12}>
<Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',width:"100%", backgroundColor: colors.blueAccent[600]}}>Submit Disposition</Button>
</Grid>

            </Grid>
          </form>
        </div>
        </Box>
      </Modal>



      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box backgroundColor={colors.primary[700]} sx={modalStyle}>
        <div className="modal-content">
            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <h3 id="modal-title">Edit Disposition {selectedItem && (selectedItem.disposition_contact)}</h3>
            <Box display="flex" justifyContent="right">
        <CancelIcon onClick={() => setIsModalOpen(false)} sx={{cursor:"pointer"}}/>
        </Box>
            </Box>
          
          <form onSubmit={handleEdit}>
          {selectedItem && (
          <Grid container spacing={1}>

          <Grid item xs={6}>
            
<TextField
              label="Disposition Contact"
              name="disposition_contact"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"10px"}}
              value={selectedItem.disposition_contact || ""}
              // InputProps={{ readOnly: true }}
            />
            <TextField    
              type="hidden"
              name="id"
              value={selectedItem.id}
              style={{ display: 'none' }}
              required="true"
            />
          </Grid>
          <Grid item xs={6}>
      <TextField
              label="Call Type"
              name="call_type"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"10px"}}
              value={selectedItem.call_type || ""}
              InputProps={{ readOnly: true }}
            />

</Grid>

<Grid item xs={12}>
<TextField
        fullWidth
        select
        label="Disposition"
        name="disposition"
        sx={{marginTop:"10px"}}
        onChange={handleInputModChange}
        value = {selectedItem.disposition || ""}
        required="true"
      >
        <MenuItem value="">Select Disposition</MenuItem>
        <MenuItem value="Call Back">Call Back</MenuItem>
        <MenuItem value="Sorted">Sorted</MenuItem>
        <MenuItem value="Not Interested">Not Interested</MenuItem>
        <MenuItem value="Escalating">Escalating</MenuItem>
        <MenuItem value="Would Like To Know More">Would Like To Know More</MenuItem>
      </TextField>
</Grid>


<Grid item xs={6}>
<TextField
              label="Category"
              name="category"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"10px"}}
              value={selectedItem.disposition_category || ""}
              InputProps={{ readOnly: true }}
            />
      </Grid>

      <Grid item xs={6}>
      <TextField
              label="Sub Category"
              name="subcategory"
              onChange={handleInputModChange}
              fullWidth
              variant="outlined"
              required="true"
              sx={{marginTop:"10px"}}
              value={selectedItem.disposition_sub_category || ""}
              InputProps={{ readOnly: true }}
            />
      </Grid>

<Grid item xs={12}>
        <TextField
          multiline
          rows={2}
          label="Extra Info"
          placeholder="Extra Info"
          onChange={handleInputModChange}
          variant="outlined"
          fullWidth
          name="disposition_info"
          value = {selectedItem.disposition_info || ""}
          required="true"
        />
        </Grid>

<Grid item xs={12}>
<Button type="submit" variant="contained" sx={{padding:"10px",marginTop:"10px",color:'white',width:"100%", backgroundColor: colors.blueAccent[600]}}>Edit Disposition</Button>
</Grid>

            </Grid>
             )}
          </form>
        </div>
        </Box>
      </Modal>

  </Box>

  );
}

export default Disposions;
