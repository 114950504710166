import React, { useEffect } from 'react';
import {
Button,
Input,
Typography,
Box,
IconButton,
} from '@mui/material';
import axios from 'axios';
import { global_link } from '../Components/Globallink';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { styled, useTheme } from '@mui/material/styles';
import { ColorModeContext, tokens } from "../theme";
import { Link, useLocation } from 'react-router-dom';

const LiveMenu = () => {
const theme = useTheme();
const colors = tokens(theme.palette.mode);
const api = axios.create({baseURL: global_link});
const accessToken = localStorage.getItem('access_token');
const headers = {
  'Authorization': `Bearer ${accessToken}`,
  'Content-Type': 'application/json',
};
const unRegister = () => {
    const event = new CustomEvent('unregisterSession', { detail: 'unregister' });
    window.dispatchEvent(event);
  };
const handleLogout = () => {
    api.get('/userlogout/',{
        headers,
        withCredentials: true,})
        .then(response => {
        localStorage.setItem("loggedIn",false);
        localStorage.removeItem('access_token');
        localStorage.removeItem("calldisabled");
        sessionStorage.removeItem("calldisabled");
        sessionStorage.removeItem('get_register');
        window.location.href="/login";})
        .catch(error => {
        console.log(error);
        });
        unRegister();
    };

return (
<Box display="flex" justifyContent="left" sx={{marginLeft:'15px'}}>

<Button
variant='contained'
sx={{
backgroundColor: colors.blueAccent[600],
color: colors.grey[100],
fontSize: "13px",
fontWeight: "bold",
padding:'8px',
height: '35px',}}
component={Link} 
to="/ldash"
>
<ExitToAppIcon sx={{ mr: "10px" }} />
Dashboard
</Button>

<Button
variant='contained'
sx={{
backgroundColor: colors.blueAccent[600],
color: colors.grey[100],
fontSize: "13px",
fontWeight: "bold",
padding:'8px',
marginLeft: '40px',
height: '35px',}}
component={Link} 
to="/lanalytics"
>
<ExitToAppIcon sx={{ mr: "10px" }} />
Analytics
</Button>


<Button
variant='contained'
sx={{
backgroundColor: colors.blueAccent[600],
color: colors.grey[100],
fontSize: "13px",
fontWeight: "bold",
padding:'8px',
height: '35px',
marginLeft: '40px',
}}
onClick={() => handleLogout(true)}
>
<ExitToAppIcon sx={{ mr: "10px" }} />
Log Out
</Button>

</Box>)
};

export default LiveMenu;
